/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "~flexmonster/flexmonster.min.css";

.mat-tooltip.red-tooltip {
    background-color: red !important;
}

*[hidden] {
    display: none !important;
}